import React, { useState, useEffect, useRef } from 'react';
import ToolbarButton from '../utils/libraries/ToolbarButton';
// import VideoPlayer from './VideoPlayer';
import MenuDispensor from '../utils/libraries/MenuDispensor';
import '../utils/css/VideoPlayer.css';
import UseCase from '../utils/libraries/UseCase';
import { setGlobalState, useGlobalState } from '../utils/state';
import {Howler, Howl} from 'howler';
import bgpattern from '../assets/Pattern.png';
import um1 from '../assets/um1.mp4';
import { BaseAPI, CityDB, assetsLocation, packageApp } from '../assets/assetsLocation';

const ToolBarGreenAndSustainable = () => {
		const MainMenuIsButtons = true;
		const [extraData, setExtraData] = useState([[], [], [], [], [], [], [], [], [], [], []]);
		const [selectedButton, setSelectedButton] = useGlobalState("selectedButton");
    const [showCardContainer, setShowCardContainer] = useState(false);
    const [sectionData, setSectionData] = useState([]);
    const [playVideo, setPlayVideo] = useState(false);
    const [ui_Element, setUI_Element] = useState(null);
    const [buttonType, setButtonType] = useState("");

    const [organicClick, setOrganicClick] = useState(false);
    const [isButtonDisable, setIsButtonDisable] = useState(false);
    
    const [currentStep, setCurrentStep] = useState(1);
    const [currentSound, setCurrentSound] = useState();
    
    // const [showTour, setShowTour] = useGlobalState("showTour");
    const [dimBg, setDimBg] = useGlobalState("dimBg");
    const [playAndPause, setPlayAndPause] = useGlobalState("playAndPause");

    const isTourRunning = useRef(false);
		const [anchorEl, setAnchorEl] = useState(null);

		const open = anchorEl;
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setDimBg(false);
			setAnchorEl(null);
			setSelectedButton("");
		};

    const simClick = async (id) => {
        try{
            setUI_Element('')
            // Fetch data from API
            const baseAPIUrl = `${BaseAPI}section/`;
            const address = !packageApp ? `${baseAPIUrl + String(id)}?db=${CityDB}` : `../../${CityDB}/section/${id}.json`;//address for fetching sectiondata
            const response = await fetch(address);//fetch section data files for specific config id
            const data = await response.json();

            const buttonName = "button" + String(id)

            // Assign UI Element to display data
            if(id === 3){ // DVS
                setAnchorEl(document.getElementById("button3"))
                setUI_Element("popuptoolbar");     
            }
            else {      // Outcomes or Building Blocks
                setUI_Element("cards");
            }
            setSectionData(data.SectionData);
            setSelectedButton(buttonName)
            setPlayVideo(false);
            setShowCardContainer(true);
            setDimBg(true);
        }
        catch(error){
            console.error('Error fetching data:', error);
        }
        return;
    }

    

    const handleVideoEnded  = () => {
        resetScreen();
    };


    // Functions for guided tour
    const audioClips = [
			{audio: !packageApp ? `${assetsLocation}${CityDB}/audio/7.mp3` : `../../${CityDB}/audio/7.mp3`},
			{audio: !packageApp ? `${assetsLocation}${CityDB}/audio/8.mp3` : `../../${CityDB}/audio/8.mp3`},
			{audio: !packageApp ? `${assetsLocation}${CityDB}/audio/9.mp3` : `../../${CityDB}/audio/9.mp3`}
    ]


var Vosound 
let idx = 1
    function tourStep1(idx){
        Howler.stop()
        setCurrentStep(idx);
        isTourRunning.current = true;
         Vosound = new Howl ({
            src: audioClips[idx-1].audio,
            html5: true
        })
        simClick(idx == 1 ? 4 : idx == 2 ? 5 : idx);
        Vosound.play();
        setCurrentSound(Vosound)
        Vosound.on("end", function () {
            if (idx < 3) {
                idx++
                tourStep1(idx)
            }else{
                stopTour()
            }
          });
        
       }
       useEffect(() => {
        if (currentSound) {
            if (playAndPause) {
                currentSound.play();
            } else {
                currentSound.pause();
            }
        }
      }, [playAndPause]);

       useEffect(() => {
        if(playVideo){
            if (playAndPause) {
                document.getElementById("um-video").play()
            } else {
                document.getElementById("um-video").pause()
            }
            }
      }, [playAndPause]);


    function stopTour(){
				setAnchorEl(null);
				setIsButtonDisable(false)
        setCurrentSound()
        setCurrentStep(1); // reset step to first
        resetScreen();
    }

    useEffect(() => {
    resetScreen();
    }, []);
    
    // Set screen to initial state

    const resetScreen = () => { 
        setSelectedButton(null); 
        setShowCardContainer(false);
        // setUI_Element(null);
        setPlayVideo(false);
        
        setDimBg(false);
        isTourRunning.current = false;
        Howler.stop();
    }



    const handleButtonClick = async (buttonId) => {
        if (!playAndPause) {
            setGlobalState("playAndPause",true)
            }
        setGlobalState("ApplicationDB",CityDB)
        if (selectedButton === buttonId) {
            // if same button clicked again, reset screen
						setGlobalState("showDC", false);
             if (buttonId !== "button3") {
              resetScreen();
              return;    
            }
          }

        setSelectedButton(buttonId);
        //  setUI_Element('')
        setShowCardContainer(false)
        if(buttonId === "button2"){     // Watch Video
            setShowCardContainer(false);
            if (isTourRunning.current){
                isTourRunning.current = false;
                stopTour();
            }
            
            
            setPlayVideo(true);
            setDimBg(true);
            return;
        }

        if(buttonId === "button1"){   
            setIsButtonDisable(true)  // Guided Tour
            setShowCardContainer(false);
            setPlayVideo(false);
            
            if (!isTourRunning.current){ 
                tourStep1(idx);
                setDimBg(true);
            }
            else { // if tour already running and button clicked again
                if(!playAndPause){
                    setGlobalState("playAndPause", true); 
                }
                stopTour();
                setDimBg(false);
            }
            
            return;
        }
        try{
						// console.log(buttonId);
            // Fetch data from API
            const baseAPIUrl = `${BaseAPI}section/`;
            const id = buttonId.at(-1);
            var address = !packageApp ? `${baseAPIUrl + id}?db=${CityDB}` : `../../${CityDB}/section/${id}.json`;//address for fetching sectiondata
						if(buttonId === "button3") {
							address = !packageApp ? `${BaseAPI}solutions?db=${CityDB}` : `../../${CityDB}/solutions.json`;
						}
            if(extraData[id-1].length == 0) {
							// console.log("API CALLED");
							const response = await fetch(address); //fetch section data files for specific config id
							const data = await response.json();
							extraData[id-1].push(data);
							// console.log(extraData[id-1].length);
						}
			
						const data = extraData[id-1][0];
						console.log(data);

            // Assign UI Element to display data
            if(buttonId === "button3"){ // DVS
                setButtonType("G");     
                setUI_Element("popuptoolbar");
								setSectionData(data.Solutions);
            }
            else {      // Outcomes or Building Blocks
							setSectionData(data.SectionData);
							setUI_Element("cards");
						}
            setPlayVideo(false);
            setShowCardContainer(true);
            setDimBg(true);
        }
        catch(error){
            console.error('Error fetching data:', error);
        }
        return;
    };


    // Dimming Background on opening any component

    useEffect(() => {
        if (dimBg){
            document.getElementById("bgvideo").style.setProperty('filter', 'brightness(35%)');
        }
        else{
            document.getElementById("bgvideo").style.setProperty('filter', 'brightness(100%)');
        }
    }, [dimBg]);

		const handleMenuItemClick = () => {
			setAnchorEl(null);
		};

    // return all buttons
    return (
        <div>
            { dimBg && <img id="pattern" className='bg-front' src={bgpattern} preload="auto"></img>}
            <div className="toolbar urban-toolbar">

                <ToolbarButton  // Guided Tour button
                    buttonId="button1"
                    isDisable = {false}
                    selectedButton={selectedButton}
                    handleButtonClick={handleButtonClick}
										handleMenuClick={()=>{}}
										MainMenuIsButtons={MainMenuIsButtons}
                >
                    Guided Tour
                </ToolbarButton>
                
                <ToolbarButton // Outcomes button
                    buttonId="button4"
                    isDisable = {isButtonDisable}
                    active={"button4" === selectedButton}
                    selectedButton={selectedButton}
                    // onClick={() => handleButtonClick("1")}
                    handleButtonClick={handleButtonClick}
										handleMenuClick={()=>{}}
										MainMenuIsButtons={MainMenuIsButtons}
                >
                    Outcomes
                </ToolbarButton>


                <ToolbarButton  // Building Blocks button
                    buttonId="button5"
                    isDisable = {isButtonDisable}
                    active={"button5" === selectedButton}
                    selectedButton={selectedButton}
                    // onClick={() => handleButtonClick("2")}
                    handleButtonClick={handleButtonClick}
										handleMenuClick={()=>{}}
										MainMenuIsButtons={MainMenuIsButtons}
                >
                    Building Blocks
                </ToolbarButton>


                <ToolbarButton // DVS button
                    buttonId="button3"
                    isDisable = {isButtonDisable}
                    active={"button3" === selectedButton}
                    selectedButton={selectedButton}
                    // onClick={() => handleButtonClick("3")}
                    handleButtonClick={handleButtonClick}
										handleMenuClick={handleClick}
										MainMenuIsButtons={MainMenuIsButtons}
                >
                    Dell Solutions
                </ToolbarButton>


                <ToolbarButton  // Watch Video button
                    buttonId="button2"
                    isDisable = {isButtonDisable}
                    selectedButton={selectedButton}
                    handleButtonClick={handleButtonClick}
										handleMenuClick={()=>{}}
										MainMenuIsButtons={MainMenuIsButtons}
                >
                    Watch Video
                </ToolbarButton>
            </div>

            {/* Display elements if clicked */}


            {/* Cards or DVS tab */}
						<MenuDispensor
							buttonType={buttonType}
							sectionData={sectionData}
							ui_element={ui_Element}
							alignItems={true}
							buttonId = {selectedButton}
							useCaseMapping = {false}
							anchorEl = {anchorEl}
							handleMenuItemClick = {handleMenuItemClick}
							handleClose = {handleClose}
							open = {open}
							showCardContainer={showCardContainer} />

            {/* Watch video tab */}
            {playVideo &&
                <div>
                    <video
                        autoPlay
                        className="player" 
                        preload="auto" 
                        id="um-video"
                        controls 
                        src={um1}
                        onEnded={handleVideoEnded}
                        >   
                    </video>
                    <div className="content-shadow-rect"></div>
                </div>
            }

            {/* UseCases/Guided Tour tab */}
            {/* {showTour && fetched && <UseCase steps={stepData} />} */}

        </div>
    );
};

export default ToolBarGreenAndSustainable;
