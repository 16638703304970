import { getApplicationDB, assetsLocation } from "../../assets/assetsLocation"; // Import assetsLocation as well

export const fetchUsecases = async () => {
  try {
    // Dynamically get the ApplicationDB value
    const ApplicationDB = getApplicationDB(); // Use getter function to fetch ApplicationDB

    if (!ApplicationDB || ApplicationDB === "UNSET") {
      throw new Error('ApplicationDB is not set yet.');
    }

    const usecasesUrl = `${assetsLocation}${ApplicationDB}/resources/usecases.json`;

    // Fetch the usecases
    const response = await fetch(usecasesUrl);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Failed to fetch usecases:', error.message);
    return null; // Handle the error as needed
  }
};