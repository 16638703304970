import React, { useState, useEffect } from "react";
import "../css/popuptoolbar.css";
import { setGlobalState, useGlobalState } from "../state";
import { useParams } from "react-router-dom";
import { Fade, MenuList, MenuItem, Paper, Popper, Grow, ClickAwayListener } from "@mui/material";
import Impact from '../assets/Impact.svg';
import Information from '../assets/Information.svg';
import Insight from '../assets/Insight.svg';
import UseCasePopupToolbar from "./UseCasePopupToolbar";
import PopupToolbar from "./PopupToolbar";
import { event } from "react-ga";

const CascadingMenu = (props) => {
	const { loadID } = useParams();
	const [selectedButton, setSelectedButton] = useState(null);
	const [showDataCard, setShowDataCard] = useGlobalState("showDC");
	const [dataObject, setDataObject] = useState(null);
	const [showUC, setShowUC] = useGlobalState("showUC");
	const [startUC, setStartUC] = useState(false);
	const [useCaseID, setUseCaseID] = useState(null);
	const [isButtonContainer, setIsButtonContainer] = useGlobalState("IsButtonContainer");
	const [uc, setUc] = useState("");
	const [solutionsData, setSolutionsData] = useState(props.sectionData);
	const [solutionsId, setSolutionsId] = useGlobalState("solutionsId");
	const [anchorEl, setAnchorEl] = useState(false);
	const [subData, setSubData] = useState(null);

	useEffect(() => {
		if (solutionsId != -1) {
			handleButtonClick(solutionsId);
		}
	}, [solutionsId]);

	const handleCloseClick = () => {
		setShowDataCard(false);
		setGlobalState("showDC", false);
		setSelectedButton(null);
	};

	useEffect(() => {
		setSolutionsData(props.sectionData);
		if (loadID != null) {
			handleButtonClick(loadID);
		}
	}, [loadID, props.sectionData]);

	const handleButtonClick = async (buttonId) => {
		setGlobalState("IsBackgroundBlur", true);
		setShowDataCard(true);
		setGlobalState("showDC", true);
		setStartUC(false);

		try {
			setSelectedButton(buttonId);
			const obj = await solutionsData.find((element) => element.id === buttonId);
			setDataObject(obj);
			if (!showUC) {
				setShowDataCard(true);
				setGlobalState("showDC", true);
			} else {
				const ucid = "uc" + String(buttonId);
				setUseCaseID(buttonId);
				setUc(ucid);
				setStartUC(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
		return;
	};

	useEffect(() => {
		handleCloseClick();
	}, [props.buttonType]);

	return (
		<div className="wrapper popup-wrapper">
			<div style={{ display: "flex" }}>
				<div className="popuptoolbar-container">
					{isButtonContainer &&
						<Popper
							anchorEl={props.anchorEl}
							open={props.open}
							TransitionComponent={Fade}
							role={undefined}
							placement="bottom-start"
							transition
							disablePortal
							className="popup-container"
						>
							{({ TransitionProps, placement }) => (
								<Grow
									{...TransitionProps}
									style={{
										transformOrigin:
											placement === 'left bottom',
									}}
								>
									<Paper>
										<ClickAwayListener onClickAway={() => { setAnchorEl(false); }}>
											<MenuList
												className="menu"
												autoFocusItem={props.open}
												id="fade-menu"
												aria-labelledby="fade-button"
												TransitionComponent={Fade}
												style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}
											>
												<MenuItem className="cascadingMenuList" id="fasolution1" onClick={(event) => { setAnchorEl(event.currentTarget); setSubData(props.sectionData[0]); }}>
													<img src={Information} style={{ width: '120%' }} alt="" />
												</MenuItem>
												<MenuItem className="cascadingMenuList" id="fasolution2" onClick={(event) => { setAnchorEl(event.currentTarget); setSubData(props.sectionData[1]); }}>
													<img src={Insight} style={{ width: '120%' }} alt="" />
												</MenuItem>
												<MenuItem className="cascadingMenuList" id="fasolution3" onClick={(event) => { setAnchorEl(event.currentTarget); setSubData(props.sectionData[2]); }}>
													<img src={Impact} style={{ width: '120%' }} alt="" />
												</MenuItem>
											</MenuList>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					}
				</div>
			</div>
			{anchorEl != false && props.buttonType == 'Use_case' &&
				<UseCasePopupToolbar
					buttonType={props.buttonType}
					sectionData={subData}
					anchorEl={anchorEl}
					handleClose={props.handleClose}
					handleMenuItemClick={props.handleMenuItemClick}
					open={props.open}
					showCardContainer={props.showCardContainer}
					cascading={true}
				/>
			}
			{anchorEl != false && props.buttonType != 'Use_case' &&
				<PopupToolbar
					buttonType={props.buttonType}
					sectionData={subData}
					buttonId={props.buttonId}
					anchorEl={anchorEl}
					handleClose={props.handleClose}
					handleMenuItemClick={() => { setGlobalState("IsButtonContainer", false); }}
					open={props.open}
					showCardContainer={props.showCardContainer}
					cascading={true}
				/>
			}
		</div>

	);
};

export default CascadingMenu;
