import React, { useEffect, useState } from "react";
import "../css/DataCard.css";
import ReactResponsiveCarousel from "./ReactResponsiveCarousel";
import { QRCodeCanvas } from "qrcode.react";
import { setGlobalState, useGlobalState } from "../state";
import { ApplicationDB, BaseAPI, carouselType, packageApp } from "../../assets/assetsLocation";
import ReactAwesomeCarousel from "./ReactAwesomeCarousel";
import { CSSTransition } from "react-transition-group";
import InitializeGoogleAnalytics from "./googleanalytics.tsx";
import TrackGoogleAnalyticsEvent from "./googleanalytics.tsx";

// Moved DynamicCarousel outside the DataCard component
const DynamicCarousel = ({ solutionGraphicsData, carouselType }) => {  
	switch (carouselType) {
	  case "1":
		return (
			<ReactResponsiveCarousel
			  solutionGraphicsData={solutionGraphicsData}
			/>
		);
	  case "2":
		return (
			<ReactAwesomeCarousel
			  solutionGraphicsData={solutionGraphicsData}
			/>
		);
	  default:
		return null;
	}
  };
  
  const DataCard = ({ data, onClose, handlePreButton, handleNexButton, showCardContainer }) => {
	useEffect(() => {
	  InitializeGoogleAnalytics();
	  TrackGoogleAnalyticsEvent(
		'data card',
		ApplicationDB,
		data
	  );
	  if (data && data.short_label) {
		const dataForAI = {};
		dataForAI[data.short_label] = data.long_desc;
		setGlobalState("currentContext", dataForAI);
	  }
	}, [data]);
  
	const [solutionGraphicsData, setSolutionGraphicsData] = useState();
	const [isHoverRight, setIsHoverRight] = useState();
	const [isHoverLeft, setIsHoverLeft] = useState();
	const [applicationDB, setApplicationDB] = useGlobalState("ApplicationDB");
	const [numGraphics, setNumGraphics] = useState(0);
	const [currentSlide, setCurrentSlide] = useGlobalState("currentSlide"); 
  
	
	useEffect(() => {
	  setShowDiv(showCardContainer);
	}, [showCardContainer]);
  
	useEffect(() => {
		if (!solutionGraphicsData) {return};
		if (currentSlide > solutionGraphicsData.length) {setCurrentSlide(1)};
		if (currentSlide < 1) {setCurrentSlide(solutionGraphicsData.length)};
	  }, [currentSlide]);


	useEffect(() => {
	  if (data?.id) {
		setSolutionGraphicsData();
		fatchSolutionGraphicsData();
		console.log("Working")
	  }
	}, [data?.id]);
	
	const fatchSolutionGraphicsData = async () => {
	  try {
		const baseAPIUrl = !packageApp ? `${BaseAPI}solution_graphics?id=${data.id}&db=${applicationDB}` : `../../${applicationDB}/solution_graphics/${data.id}.json`;
		const address = baseAPIUrl; //address for fetching sectiondata
		const response = await fetch(address); //fetch section data files for specific config id
		const responseData = await response.json();
		let SolutionGraphics = responseData.SolutionGraphics.filter((item) => {
		  if (item.solution_id == data.id) {
			return item;
		  }
		});
		setNumGraphics(SolutionGraphics.length);
		setSolutionGraphicsData(SolutionGraphics);
		setCurrentSlide(1);
	  } catch (error) {
		console.error("Error fetching data:", error);
	  }
	};
  
	const [showDiv, setShowDiv] = useState(false);
  
	return data ? (
	  <CSSTransition
		in={showDiv}
		timeout={225} // Duration of the animation in milliseconds
		classNames="animationMenu" // Your CSS class for animations
		unmountOnExit
		mountOnEnter
	  >
		<div className="rectangle">  
		  <div className="LeftArrow blink">
			{numGraphics > 1 && carouselType == "1" ?
			  <svg className={isHoverLeft ? "nex-prev-hover" : "nex-prev"} onClick={() => handlePreButton()} onMouseEnter={() => setIsHoverLeft(true)} onMouseLeave={() => setIsHoverLeft(false)} width="6.6vh" height="6.6vh" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="56" height="56" rx="28" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 59 59)" />
				<rect x="1.5" y="1.5" width="59" height="59" rx="29.5" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 62 62)" stroke="#0B37A4" stroke-opacity="0.6" stroke-width="3" />
				<path d="M34.2 24.6001L27.8 31.0001L34.2 37.4001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<defs>
				  <linearGradient id="paint0_linear_35_413" x1="4.4" y1="14.4" x2="48" y2="20.8" gradientUnits="userSpaceOnUse">
					<stop stop-color="#040C32" />
					<stop offset="1" stop-color="#040C38" stop-opacity="0.82" />
				  </linearGradient>
				</defs>
			  </svg>
			  : ""}
		  </div>
		  <div className="left-section">
			<h2 className="heading">{data.short_label}</h2>
			<hr className="divider" style={{ marginTop: '6%' }} />
			<p className="content" dangerouslySetInnerHTML={{ __html: data?.long_desc }}/>

				<div className="left-image-container" style={{ display: 'flex', alignItems: 'center' }}>
					<QRCodeCanvas
						className="left-image"
						id="qrCode"
						value={data.details_url}
						bgColor={"#fff"}
						level={"H"}
						style={{ width: '1vw', height: 'auto' }}
					/>
					<p className="scan-content"><a href={data.details_url} target="_blank">Scan QR or click <br /> for more details</a>
					</p>

				</div>



			<div className="slide-count"> Showing Visual: {solutionGraphicsData ? `${currentSlide} of ${solutionGraphicsData.length}` : ""} </div>
	
		  </div>
		  <div className="right-section">
			<div className="image-container">
			  {solutionGraphicsData && solutionGraphicsData.length > 0 ?
				<DynamicCarousel solutionGraphicsData={solutionGraphicsData} carouselType={carouselType} />
				: ''}
			</div>
		  </div>
		  <div className="RightArrow blink">
			{numGraphics > 1 && carouselType == "1" ?
			  <svg className={isHoverRight ? "nex-prev-hover" : "nex-prev"} onMouseEnter={() => setIsHoverRight(true)} onMouseLeave={() => setIsHoverRight(false)} onClick={() => handleNexButton()} width="6.6vh" height="6.6vh" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="5" y="61" width="56" height="56" rx="28" transform="rotate(-90 5 61)" />
				<rect x="2.5" y="63.5" width="61" height="61" rx="30.5" transform="rotate(-90 2.5 63.5)" stroke="#0B37A4" stroke-opacity="0.4" stroke-width="5" />
				<path d="M29.8 26.6001L36.2 33.0001L29.8 39.4001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<defs>
				  <linearGradient id="paint0_linear_35_410" x1="9.4" y1="75.4" x2="53" y2="81.8" gradientUnits="userSpaceOnUse">
					<stop stop-color="#0F2266" />
					<stop offset="1" stop-color="#040C38" stop-opacity="0.72" />
				  </linearGradient>
				</defs>
			  </svg>
			  : ""}
		  </div>
		  <div className="close-button" onClick={onClose}>X</div>
		</div>
	  </CSSTransition>
	) : (
	  <></>
	);
  };
  

export default DataCard;
