//export const assetsLocation = "http://dell-edge-test.s3-accelerate.amazonaws.com/BabylonAssets/"
export const assetsLocation = "http://repository.storyproc.com/"

export const CityDB = "city"
export const ApplicationDB = "factory"
export const ApplicationDB_IOC = "factory"
export const BaseAPI = "https://bubble-backend.storyproc.com/"
export const carouselType = "1"
export const packageApp = false
export const TVScreenSolutions = true
export const TRACKING_ID = "G-728XS2G376"
export function getApplicationDB() {
    return "factory";
}