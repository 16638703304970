import React from 'react';
import Navbar from './Navbar';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import AnimatedRoutes from './AnimatedRoutes';
import ReactGA from 'react-ga4';
import { packageApp, TRACKING_ID } from '../assets/assetsLocation';
ReactGA.initialize(TRACKING_ID);

function App() {

  return (
		<>
		{
			packageApp ?
			<HashRouter>
				<Navbar />
				<AnimatedRoutes />
			</HashRouter>
			:
			<BrowserRouter>
				<Navbar />
				<AnimatedRoutes />
			</BrowserRouter>
		}
		</>
  );
}

export default App;
