
// import Toolbar from './ToolBar';

import '../utils/css/UrbanMobility.css';
import { Howl, Howler } from "howler";

import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { setGlobalState } from '../utils/state';
import ToolBarSafetyAndSecurity from './ToolBar_SafetyAndSecurity';
import { useEffect } from 'react';
import { CityDB } from '../assets/assetsLocation';
import Landscape from "../utils/libraries/Landscape";
import scpano from '../assets/scpano.mp4';

function SafetyAndSecurity() {

	const navbuttontext = '< Edge City • Digital Cities';
	let navigate = useNavigate();
	useEffect(() => {
		setGlobalState("ApplicationDB", CityDB)
	}, [])


	return (
		<>
			<Landscape />
			<div className="App">

				{/* <div className='wrapper video-background'> */}
				<div className='UM-video-background'>
					<video id="bgvideo" autoPlay="autoplay" preload="auto" className="bg" src={scpano} muted loop playsInline></video>
				</div>


				<motion.div
					className='wrapper'
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 2 }}
				>
					<div className="blur-ell1"></div>
					<div className="blur-ell3"></div>
					<div className="blur-ell4"></div>
					<div className="blur-rect"></div>
					<div className='urban-nav-container'>
						<button className="nav-button" onClick={() => { Howler.stop(); setGlobalState("UmToDigitalCity",false);navigate("/digital-cities"); }}>{navbuttontext}</button>
						<h1 className="um-head">Safety and Security</h1>
						<p className="um-sub">Create safer cities with integrated solutions for public security and protection.</p>
					</div>


					<ToolBarSafetyAndSecurity />


				</motion.div>
			</div>
		</>
	);
}

export default SafetyAndSecurity;
