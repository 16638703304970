import React from 'react';
import '../utils/css/EdgeCity.css';
import manu from '../assets/Manufacturing.png';
import retail from '../assets/Retail icon.png';
import ioc from '../assets/IOC Icon.png';
import dc from '../assets/Cities Icon.png';
import { useState } from 'react';
import startIcon from '../assets/Start icon.png';
import bgImage from '../assets/Starting VIsual.png';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Delayed from '../utils/libraries/Delayed';
import { setGlobalState, useGlobalState } from '../utils/state';
import Landscape from "../utils/libraries/Landscape";
import Edge_Landing from '../assets/Edge_Landing.mp4';
// https://res.cloudinary.com/dfira7ea4/video/upload/v1686860462/Edge_City_BG_or20ix.mp4

function EdgeCityScreen() {

    const [loading, setLoading] = useState(true);
    const [mfToEdgeCity, setMfToEdgeCity] = useGlobalState("MfToEdgeCity");

    let navigate = useNavigate();
    console.log("mfToEdgeCity", mfToEdgeCity);
    return (
        <>
            <Landscape />
            <motion.div
                className='wrapper'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                {
                    loading && mfToEdgeCity ?
                        (
                            <div className='wrapper'>
                                <img className='bg' src={bgImage}></img>

                                <div className='blur-ell1'></div>
                                <div className='blur-ell3'></div>
                                <div className='blur-ell4'></div>
                                <div className='blur-rect'></div>

                                <div onClick={() => { setLoading(false); setGlobalState("playBgMusic", true); }} className="enter-factory">
                                    <img className="start-icon" src={startIcon} />
                                    <div className="enter-text"> Start Experience </div>
                                </div>


                                <div className='dc-head'>edge city</div>
                                <div className="dc-sub">Explore how you can simplify your edge and drive innovation across industries with Dell Technologies.</div>
                            </div>
                        ) : (
                            <div className='wrapper'>
                                <div className='wrapper'>
                                    <video id="bgvideo" autoPlay="autoplay" preload="auto" className='bg' src={Edge_Landing} muted playsInline></video>
                                </div>

                                {/* Video is 11s long */}
                                <Delayed waitBeforeShow={mfToEdgeCity ? 3000 : 0}>
                                    <motion.div
                                        className='wrapper'
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 2 }}
                                    >
                                        <div className='blur-ell1'></div>
                                        <div className='blur-ell3'></div>
                                        <div className='blur-ell4'></div>
                                        <div className='blur-rect'></div>




                                        <div className='info-button manufacturing clickable' onClick={() => { setGlobalState("UmToManufacturing", true); navigate("/manufacturing"); }}></div>
                                        <div className='info-button retail'></div>
                                        <div className='info-button digcities clickable' onClick={() => { navigate("/digital-cities"); }}></div>


                                        <h1 className='info-head manu-text button-text clickable' onClick={() => { setGlobalState("UmToManufacturing", true); navigate("/manufacturing"); }}>Manufacturing</h1>
                                        <h1 className='info-head retail-text'>Retail</h1>
                                        <h1 className='info-head dc-text button-text clickable' onClick={() => { navigate("/digital-cities"); }}>Digital Cities</h1>


                                        <p className="info-sub manu-text button-text clickable" onClick={() => { setGlobalState("UmToManufacturing", true); navigate("/manufacturing"); }}>See how Dell's portfolio of edge offerings helps manufacturers unleash the full potential of Industry 4.0.</p>
                                        <p className="info-sub retail-text">Intelligent Retail Automation to simplify the retail experience, help reduce shrinkage, and unlock insights.</p>
                                        <p className="info-sub dc-text button-text clickable" onClick={() => { navigate("/digital-cities"); }}>Your resources for a Digital City with the conveniences, security and sustainability your citizens demand. And deserve!</p>

                                        <img className='icon manu-icon clickable' src={manu} onClick={() => { setGlobalState("UmToManufacturing", true); navigate("/manufacturing"); }}></img>
                                        <img className='icon retail-icon' src={retail}></img>
                                        <img className='icon dc-icon clickable' src={dc} onClick={() => { navigate("/digital-cities"); }}></img>



                                        <div className='dc-head'>edge city</div>
                                        <div className="dc-sub">Explore how you can simplify your edge and drive innovation across industries with Dell Technologies.</div>
                                    </motion.div>
                                </Delayed>
                            </div>
                        )
                }
            </motion.div>
        </>
    );
}

export default EdgeCityScreen;