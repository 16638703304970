import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import EdgeCityScreen from './EdgeCityScreen';
import DigitalCityScreen from './DigitalCityScreen';
import UrbanMobility from './UrbanMobility';
import Manufacturing from './Manufacturing';
import IOC from './IOC';
import { AnimatePresence } from 'framer-motion';
import GreenAndSustainable from './GreenAndSustainable';
import SafetyAndSecurity from './SafetyAndSecurity';

function AnimatedRoutes() {
    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<EdgeCityScreen />} />
                <Route path="/safety-security" element={<SafetyAndSecurity />} />
                <Route path="/green-sustainable" element={<GreenAndSustainable />} />
                <Route path="/digital-cities" element={<DigitalCityScreen />} />
                <Route path="/urban-mobility" element={<UrbanMobility />} />
                <Route path="/manufacturing/*" element={<Manufacturing />} />
                <Route path="/ioc/*" element={<IOC />} />
                <Route path="/uoc/*" element={<IOC />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes