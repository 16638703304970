
// import Toolbar from './ToolBar';

import '../utils/css/UrbanMobility.css';
import { Howl, Howler } from "howler";

import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { setGlobalState } from '../utils/state';
import { useEffect } from 'react';
import Toolbar from './ToolBar_UrbanMobility';
import { CityDB } from '../assets/assetsLocation';
import Landscape from "../utils/libraries/Landscape";
import umpano_mpgp0u from '../assets/umpano_mpgp0u.mp4'

function UrbanMobility() {

	const navbuttontext = '< Edge City • Digital Cities';
	let navigate = useNavigate();
	useEffect(() => {
		setGlobalState("ApplicationDB", CityDB)
	}, [])


	return (
		<>
			<Landscape />
			<div className="App">

				<div className='UM-wrapper video-background'>
					<video id="bgvideo" autoPlay="autoplay" preload="auto" className="bg" src={umpano_mpgp0u} muted loop playsInline></video>
				</div>


				<motion.div
					className='wrapper'
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 2 }}
				>
					<div className="blur-ell1"></div>
					<div className="blur-ell3"></div>
					<div className="blur-ell4"></div>
					<div className="blur-rect"></div>
					<div className='urban-nav-container'>
						<button className="nav-button" onClick={() => { Howler.stop(); setGlobalState("UmToDigitalCity", false); navigate("/digital-cities"); }}>{navbuttontext}</button>
						<h1 className="um-head">URBAN MOBILITY</h1>
						<p className="um-sub">Streamline traffic, enforce regulations, and optimize transportation systems.</p>
					</div>


					<Toolbar />


				</motion.div>
			</div>
		</>
	);
}

export default UrbanMobility;
