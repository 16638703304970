import React, { useEffect } from 'react';
import RotateImg from "../assets/Group 89.png"
const Landscape = () => {
  return (
    <div id='lock' style={{justifyContent:'center',alignItems:'center',height:"100%",width:'100%'}}>
      <img src={RotateImg}/>
      {/* <h1>PLEASE ROTATE YOUR DEVICE</h1> */}
    </div>
  );
};

export default Landscape;
