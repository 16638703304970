// globalState.js
import { useRef } from 'react';

const isUseCaseStoryOpenRef = { current: false };
const showAllHoverRef = { current: false };

export const setIsUseCaseStoryOpen = (value) => {
  isUseCaseStoryOpenRef.current = value;
};

export const getIsUseCaseStoryOpen = () => {
  return isUseCaseStoryOpenRef.current;
};

export const setShowAllHover = (value) => {
  showAllHoverRef.current = value;
};

export const getShowAllHover = () => {
  return showAllHoverRef.current;
};