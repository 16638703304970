import lightsData from "../../data/lights.json";
import { Color3, Animation } from "@babylonjs/core";

const resetLights = (scene) => {
	const defaultSettings = lightsData["default"];
	const light = scene.getLightByName("light");

	var rgb = light.diffuse;
	var lightColorAnimation = new Animation("lightColorAnimation", "diffuse", 60, Animation.ANIMATIONTYPE_COLOR3, Animation.ANIMATIONLOOPMODE_CYCLE);
	var keys = [
		{frame: 0, value: new Color3(rgb.r, rgb.g, rgb.b)},
		{frame: 60, value: new Color3(defaultSettings["r"], defaultSettings["g"], defaultSettings["b"])},
	];
	lightColorAnimation.setKeys(keys);
	scene.beginDirectAnimation(light, [lightColorAnimation], 0, keys[keys.length - 1].frame, false, 1);

	var lightIntensityAnimation = new Animation("lightIntensityAnimation", "intensity", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
	keys = [
		{frame: 0, value: light.intensity},
		{frame: 60, value: defaultSettings["intensity"]},
	];
	lightIntensityAnimation.setKeys(keys);
	scene.beginDirectAnimation(light, [lightIntensityAnimation], 0, keys[keys.length - 1].frame, false, 1);
}

const changeLightColor = (scene, newColor) => {
	const light = scene.getLightByName("light");

	var lightColorAnimation = new Animation("lightColorAnimation", "diffuse", 60, Animation.ANIMATIONTYPE_COLOR3, Animation.ANIMATIONLOOPMODE_CYCLE);
	var keys = [
		{frame: 0, value: light.diffuse},
		{frame: 120, value: newColor},
	];
	lightColorAnimation.setKeys(keys);
	scene.beginDirectAnimation(light, [lightColorAnimation], 0, keys[keys.length - 1].frame, false, 5);
}

const changeLightIntensity = (scene, newIntensity) => {
	const light = scene.getLightByName("light");

	var lightIntensityAnimation = new Animation("lightIntensityAnimation", "intensity", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
	var keys = [
		{frame: 0, value: light.intensity},
		{frame: 120, value: newIntensity},
	];
	lightIntensityAnimation.setKeys(keys);
	scene.beginDirectAnimation(light, [lightIntensityAnimation], 0, keys[keys.length - 1].frame, false, 1);
}

const changeLightEmotion = (scene, emotion) => {
	const defaultSettings = lightsData["default"];
	const lightSettings = lightsData[emotion] || { r: 0, g: 0, b: 0, intensity: 0 };

	const newColor = new Color3(defaultSettings["r"] + lightSettings["r"], defaultSettings["g"] + lightSettings["g"], defaultSettings["b"] + lightSettings["b"])
	const newIntensity = defaultSettings["intensity"] + lightSettings["intensity"];

	changeLightColor(scene, newColor);
	changeLightIntensity(scene, newIntensity);
}

export {resetLights, changeLightEmotion}