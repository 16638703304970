import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { TRACKING_ID } from "../../assets/assetsLocation"; // OUR_TRACKING_ID

const InitializeGoogleAnalytics = () => {
    // Initialize UA
    ReactGA.initialize(TRACKING_ID);
    // Initialize GA4 - Add your measurement ID
    ReactGA4.initialize(TRACKING_ID);

    console.log("GA INITIALIZED");
};

const TrackGoogleAnalyticsEvent = (category: string, action: string, label: string) => {
    // Send UA Event
    // ReactGA.initialize(TRACKING_ID);
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
    // Send GA4 Event
    ReactGA4.event({
        category: category,
        action: action,
        label: label,
    });
    // ReactGA4.ga('send', 'event', category, action, label);/
    console.log("GA event:", category, ":", action, ":", label);

};
const TrackGoogleAnalyticsTiming = (category: string, variable: string, value: number, label: string) => {
    // Send UA Event
    // ReactGA.initialize(TRACKING_ID);
    ReactGA.timing({
        category: category,
        variable: variable,
        value: value,
        label: label
    });
    // ReactGA4.ga('send', 'event', category, action, label);/
    console.log("GA timing:", category, ":", variable, ":", value, ":", label);

};


export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, TrackGoogleAnalyticsTiming };
