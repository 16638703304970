import React, { useMemo, useEffect, useRef, useState } from "react";
import "../css/cardcontainer.css";
import { BaseAPI, ApplicationDB, packageApp } from "../../assets/assetsLocation";
import { setGlobalState, useGlobalState } from "../state";
import { Fade, MenuList, MenuItem, Paper, Popper, Grow, ClickAwayListener } from "@mui/material";
import ReactGA from 'react-ga';

function CardMapped(props) {
  const [transitionValue, setTransitionValue] = useState();
  const [transformValue, setTransformValue] = useState();
  const [linkedData, setLinkedData] = useState(null);
  const [highlightCurrentCard, sethighlightCurrentCard] = useGlobalState("highlightCurrentCard");
  const panelValue = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  let mouseX, mouseY;
  let transformAmount = 20;
  const transformPanel = (e) => {
    mouseX = e.pageX;
    mouseY = e.pageY;

    const centerX =
      panelValue.current.offsetLeft + panelValue.current.clientWidth / 2;
    const centerY =
      panelValue.current.offsetTop + panelValue.current.clientHeight / 2;

    const percentX = (centerX - mouseX) / (panelValue.current.clientWidth / 2);
    const percentY = -(
      (centerY - mouseY) /
      (panelValue.current.clientHeight / 2)
    );

    setTransformValue(
      "perspective(1000px) rotateY(" +
      percentX * transformAmount +
      "deg) rotateX(" +
      percentY * transformAmount +
      "deg) scale3d(1.1,1.1,1.1)"
    );
  };

  const handleMouseEnter = () => {
    setTimeout(() => {
      setTransitionValue("");
    }, 100);
    setTransitionValue("transform 0.1s");
  };

  const handleMouseLeave = () => {
    setTransitionValue("transform 0.1s scale3d(1,1,1)");

    setTimeout(() => {
      setTransitionValue("");
    }, 100);
    setTransformValue(
      "perspective(1000px) rotateY(0deg) rotateX(0deg) scale3d(1,1,1)"
    );
  };
  const [currentPage, setCurrentPage] = useState(0);

  const indexArr = [1, 4];

  const [isOpen, setIsOpen] = useState(false);

  const toggleTooltip = (play) => {
    props.handleUcClick(props.id, play);
  };

  const fetchData = async () => {
    var address = !packageApp ? `${BaseAPI}element_linkages?db=${ApplicationDB}&element_id=${props.id}` : `../../${ApplicationDB}/element_linkages/${props.id}.json`;
		if(props.link_type != null) {
			address = !packageApp ? `${BaseAPI}element_linkages?db=${ApplicationDB}&element_id=${props.id}&link_type="${props.link_type}"` : `../../${ApplicationDB}/element_linkages/${props.link_type}/${props.id}/.json`;
		}
    const response = await fetch(address); //fetch section data files for specific config id
    const data = await response.json();
    setLinkedData(data);
  }

  useEffect(() => {
    if (props.id != null) fetchData();
  }, [props.id, props.link_type]);

  useEffect(() => {
    if (props.selectedCard == props.id && !isOpen) {
      setIsOpen(true);
    }
    if (props.selectedCard != props.id && isOpen) {
      setIsOpen(false);
    }
  }, [props.selectedCard, linkedData]);
  const open = anchorEl;
  const handleClick = (event) => {
    // ga for dive deeper
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(null);
    // setSelectedButton("selectedButton")
  };
  const handleMenuItemClick = (id, label) => {
    ReactGA.event({
      category: 'Dive Deeper',
      action: 'Clicked button',
      label: label
    });

    setGlobalState("HoverUseCaseId", id);
    toggleTooltip(true);
    setAnchorEl(null);
    // setSelectedButton("selectedButton")
  };

  useEffect(() => {
    const textElements = document.querySelectorAll('.card-content');
    const calculateFontSize = (element) => {
      const fontSize = parseFloat(getComputedStyle(element).fontSize);

      if (fontSize < 9) {
        element.style.display = 'none';
      } else {
        element.style.display = 'block';
      }
    };

    textElements.forEach((element) => {
      calculateFontSize(element);
    });

    const handleResize = () => {
      textElements.forEach((element) => {
        calculateFontSize(element);
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  return (
    <div id="container" className="big-container">
      <div
        ref={panelValue}
        id="panel"
      >
        <div
          id="panel-container"
          style={{ transition: transitionValue, transform: transformValue, boxShadow: "-30px 30px 35px -10px #00000066" }}
        >
          <div
            className={`card big-card ${indexArr.includes(props.index) ? props.alignItems ? "" : "extra-margin-card" : ""} ${highlightCurrentCard == props.id ? "current-card" : ""}`}
          >
            <h2 className="card-heading">{props.heading}</h2>
            <hr className="card-divider" />
            <p className="card-content" dangerouslySetInnerHTML={{ __html: props.content }}></p>

            <div className="tooltip-container">
              <button onClick={(event) => { { toggleTooltip(false); handleClick(event) } }} className="tooltip-button">
                See How <span style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}><svg className="dive-deeper-arrow" width="0.6vw" height="1.3vh" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1.09998L7 6.49998L0.999999 11.9" stroke-linecap="round" stroke-linejoin="round" />
                </svg></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {(isOpen && linkedData) &&
        <Popper
          anchorEl={anchorEl}
          open={open}
          TransitionComponent={Fade}
          role={undefined}
          placement="right"
          transition
          disablePortal
          className="card-mapped-container"
          style={{ zIndex: 9999, position: 'relative' }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'left',
                transform: 'translate(3%, 50%)'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="fade-menu"
                    aria-labelledby="fade-button"
                    TransitionComponent={Fade}
                    className="card-mapped-container"
                  >

                    {linkedData && linkedData.element_linkages.map((element) => {
                      return (
                        <MenuItem className="use-case-item" onClick={() => { handleMenuItemClick(element.linked_id, element.short_label); }}>{element.short_label}</MenuItem>
                      )
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      }
    </div>
  );
}

export default CardMapped;
